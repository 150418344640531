// Colors
$primary:          #0D71C6;
$primary-lightest: #CEE3F4;
$primary-light:    #88B2D6;
$primary-dark:     #09599D;

// Orange
$orange:        #F78E00;
$orange-dark:   #E87D00;
$orange-light:  #FFB900;
$orange-medium: #fb9f48;

// Gray
$gray-light:    #F5F6F7;
$gray:          #C9C9C9;
$gray-medium:   #B5B5B5;
$gray-dark:     #8D8D8D;

// White
$white:         #FFFFFF;
$white-ice:     #F7F7F7;

// Green
$green:         #108E31;
$green-light:   #66ddab;
$green-ocean:   #0DD698;

// Red
$red:           #eb472b;

// Fonts
$font-primary:   'Lato', sans-serif;
$font-secondary: 'Exo 2', sans-serif;

// shadows
$shadow-default: 0 0 15px 0 rgba(black, 0.1), 1px 1px 2px 0 rgba(black, 0.075);
$shadow-hover:   0 0 20px 0 rgba(black, 0.2), 1px 1px 5px 0 rgba(black, 0.050);
