.modal{
  background-color: rgba(0,0,0,0.3);
  pointer-events: none;
  display: block !important;  
  &.show{ 
    pointer-events: all; 
    display: block;
  }
  &.fullscreen{
    overflow: hidden;
  }
}
.modal-message{
  color: #999 !important;
}
.modal-body.fullscreen{
  max-height: 70vh;
  overflow-y: auto;
}