.month-appointment-container{
    flex-wrap: nowrap !important;
}
.month-appointment-title{
    font-size: 11px !important;
    max-width: 80%;
}
.month-appointment-discipline{
    font-size: 11px !important;
    line-height: normal;
}
.month-appointment-content{
    margin-top: 7px !important;
    font-size: 11px !important;
    line-height: normal;
}