.week-calendar-cell{
    min-height: 110px;
    background-color: white;
    &.over{
     //  background-color: rgba(0, 123, 255, 0.5)
     background-color: rgb(201, 241, 255)
    }
    &.current:not(.over){
        background-color: #f1f1f1;
    }
    div:not(:first-child){
        margin-left: 10px;
    }
}