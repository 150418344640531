.calendar-header{
    & div{
        height: 99px;
        display: flex;
        align-items: center;
    }
    & .form-group{
        margin: 0 !important;
    }
    .btn-pointer {
        cursor: pointer;
    }
}

#optionCategory {
    min-width: 260px;
}

.calendar--add-button{
    @media (max-width: 640px){
        position: fixed;
        bottom: 80px;
        right: 20px;
        z-index: 1;
    }
}