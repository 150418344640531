// Sort Bar
.sort-bar {
    padding: 7px 0;
    height: auto;
    width: 100%;
    
    .select2 {
        width: 150px !important;
        margin-top: -13px;

        @include media-breakpoint-down(sm) {
            display: none;
        }
        
        .select2-selection__rendered {
            text-align: left !important;
        }
        
        .select2-selection {
            padding: 0.2rem;
            
            .select2-selection__arrow {
                b {
                    border-color: $primary transparent transparent transparent;
                    border-width: 7px 6px 0 6px;
                    margin-left: -10px;
                    margin-top: -3px;
                }
            }
        }
    }
    
    .fb-icons {
        display: inline-block;
    
        @include media-breakpoint-down(sm) {
            display: none;
        }
        
        a {
            border: 1px solid transparent;
            padding: 2px 1px 1px 2px;
            display: inline-block;
            color: $gray;
            text-align: center;
            border-radius: 3px;
            margin-left: 13px;
            font-size: 22px;
            height: 35px;
            width: 35px;

            &.active,
            &:hover {
                border-color: $primary;
                text-decoration: none;
            }
        }
    }

    .btn {
        margin-top: -9px;
        float: right;

        .mob {
            display: none;

            @include media-breakpoint-down(sm) {
                display: inline-block;
                margin-right: 10px;
            }
        }

        span {
            @include media-breakpoint-down(sm) {
                display: none;
            }
        }
    }
}
