.headerIcon___wrapper {
  display: flex;
  justify-content: space-around;
  flex-wrap: nowrap
}

.headerIcon___icon-button {
  font-size: 30px;
  cursor: pointer;
  align-items: center;
}

.icon-button-container {
  width: unset;
  max-width: 100px;
  align-items: center;
}

@media screen and (max-width: 949px) {
  .headerIcon___wrapper {
    width: 100%;
  }
}