.initials {
    width: 65px;
    height: 65px;
    border-radius: 65px;
    font-size: 30px;
    background-color: $gray-medium;
    color: $gray-light;
    font-weight: bold;
    text-align: center;
    margin: auto;
    padding: 10px 0;
}

.initials-modal {
    width: 65px;
    height: 65px;
    border-radius: 65px;
    font-size: 30px;
    background-color: $gray-medium;
    color: $gray-light;
    font-weight: bold;
    text-align: center;
    margin: 0;
    padding: 10px 0;
    float: left;
}