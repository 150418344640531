.plans__list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .card {
    margin: 10px;
    width: 47%;
  }

  @media screen and (max-width: 1089px) {
    .card {
      width: 100%;
    }
  }
}

.modal-body.fullscreen {
  height: 80vh;
  
}

.modal.fullscreen .modal-dialog {
  max-width: 90%!important;
}