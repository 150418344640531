@import '../../globals';


.calendar-nav{
    font-size: 20px;
    display: flex;
    font-weight: bold;
    padding: 20px;
    border-top: 1px solid #E0E0E0;
    margin: 0 -70px 0 -50px;
    @media (max-width: 640px) {
        margin: 0 0px 0 0px;
        display: flex;
        justify-content: space-between
    }
    span{
        //min-width: 300px;
    }
    .calendar-nav-actions{
        margin-top: -5px;
    }
    button {
        background-color: transparent;
        border: none;
        padding: 0 5px;
        color: #D1D1D1;
        font-size: 25px;
        transform: translateY(3px);
    }
    @media print {
        button{
            display: none;
        }
    }
  }
  @media print {
    .calendar-nav{
        margin: 15px;
    }
  }
  .calendar-nav--label{
      min-width: 300px;
      @media (max-width: 640px){
          min-width: initial;
          max-width: 240px;
      }
  }