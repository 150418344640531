@import '../../globals';

.card-appointment{
    padding: 10px 20px 10px 10px !important;
    display: flex;
    .icon{
        cursor: pointer;
        width: 25px;
        //margin-right: 10px;
        &:hover{
            color: $primary;
            font-weight: bold;
        }
    }
    @media print {
        .fixed-actions{
            display: none;
        }
        .icon{
            display: none;
        }
    }
}
.card-appointment-discipline, .card-appointment-content, .card-appointment-title{
    font-size: 11px !important;
    line-height: normal;
}