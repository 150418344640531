.change-avatar{
  border-radius: 100%;
  position: relative;
  overflow: hidden;
  width: 220px; 
  height: 220px;
  border: 2px solid #1c77c4;
  .ca-button{
      width: 100%;
  }
}
.photo-btn-close{
  background-color: #1c77c4!important;
  position: absolute;
  color: #fff!important;
  font-size: 28px;
  z-index: 1;
  cursor: pointer;
  border-radius: 30px;
  border: 2px solid #1c77c4;
  left: 38px;
  top: 30px;
  padding: 0px;
}

.card-password {
  padding: 25px 25px 10px 25px;
  margin-top: 20px;
  position: absolute; 
  z-index: 2;
  display: none;
}

.input-password:hover ~ .card-password {
  display: block;
}