.language-select{
    background-color: #F7F7F7;
    border: 1px solid #e1e0e0;
    padding: 15px;
    margin-bottom: 20px;
    color: inherit;
}

@media (max-width: 767.98px) {
    .language-select{
        width: 100%;
        margin-bottom: 40px;
    }
}