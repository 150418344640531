.card2 {
  transition: all .2s ease-in-out;
  box-shadow: 0 0 8px 1px rgba(0, 0, 0, 0.137);
  border: none;
  margin-bottom: 30px;
  background-color: white;
}

.card-password {
  padding: 25px 25px 10px 25px;
  margin-top: 20px;
  position: absolute; 
  z-index: 2;
  display: none;
}

.input-password:hover ~ .card-password {
  display: block;
}

.pass-icon {
  position: absolute;
  top: 42px;
  right: 20px;
  cursor: pointer;
}