.calendar-waiting {
  display: flex;
  width: 100%;
  height: 100%;
  position: fixed;
  align-items: center;
  justify-content: center;
  top: -50px;
  left: 0;
  z-index: 10000;
  @media (max-width: 640px) {
    h4 {
      margin: 20px;
      font-size: 15px !important;
      text-align: center;
    }
  }
}

#calendar-container {
  #error-event-modal {
    z-index: 10000;
  }
  #add-event-modal {
    z-index: 10001;
  }
  #reassign-modal {
    z-index: 10001;
  }
  #status-modal {
    z-index: 10001;
  }
  #status-change-modal {
    z-index: 10001;
  }
  #class-detail-modal {
    z-index: 10000;
  }
  #event-detail-modal {
    z-index: 10000;
  }
  #event-remove-modal {
    z-index: 10001;
  }
}
