.periodo{
    & span{
        min-width: 256px;
        display: inline-block;
    }
}
.day-calendar-wrapper{
    display: flex !important;
    @media print { 
        margin: 50px;
    }    
}

.class-wrapper{
    width: 100%
}