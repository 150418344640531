.badge {
    text-transform: uppercase;
    background-color: $gray-medium;
    font-weight: normal;
    color: $gray-light;

    &-warning {
        background-color: #FF9A10;
        color: #fff;
    }

    &-danger {
        background-color: #EB3F00;
        color: #fff;
    }

    &-warning {
        background-color: #FF9A10;
        color: #fff;
    }

    &-info {
        background-color: #56F700;
        color: #fff;
    }

    &-light {
        background-color: #f8f8f8;
        color: #9a9a9a;
    }

    &-dark {
        background-color: #000000;
        color: #ffffff;
    }
}
