@import '../../../globals';

.week-calendar-nav {
  font-size: 20px;
  display: flex;
  font-weight: bold;
  padding: 20px;
  border-top: 1px solid #e0e0e0;
  margin: 0 -70px 0 -50px;
  i {
    margin-left: 15px;
    cursor: default;
    font-size: 29px;
    color: $primary;
  }
}

.week-calendar-wrapper {

  @media (max-width: 640px){
    height: 59px;
  }
  @media print{

    margin: 15px;
    gap: 0px;
    & > div {
      border: 1px solid gainsboro;
    }
  }
}
@media print {
  .week-calendar-nav {
    margin: 15px;
  }
 
}

