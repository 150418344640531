.week-card-appointment{
    margin-bottom: 5px !important;
    @media (max-width: 640px){
//        max-width: 200px !important;
    }
}
@media print {
    .week-card-appointment-mobile{
        display: none;
    }
}
.week-card-appointment-mobile--title{
    font-size: 11px !important;
    line-height: normal;
}
.week-card-appointment-mobile--discipline{
    font-size: 11px !important;
    line-height: normal;
}
.week-card-appointment-mobile--content{
    font-size: 11px !important;
    line-height: normal;
}