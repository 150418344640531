.content-wrapper--expanded{
    .container{
        max-width: 100%;
    }
}

.container{
    max-width: 100%;
}
.ud-menu li:first-child {
    display: none!important;
}