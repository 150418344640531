.sticky-top {
    top: 100px;
}

.card {
    @include transition;
    box-shadow: $shadow-default;
    border: none;
    margin-bottom: 30px;

    &:hover {
        box-shadow: $shadow-hover;
    }

    .edit-card {
        font-size: 9px;
        text-align: center;
        color: $gray-medium;
        background: transparent;
        border: none;
        padding: 10px;
        cursor: pointer;
        position: absolute;
        right: 10px;
        bottom: 10px;

        i {
            display: block;
            font-size: 18px;
        }
    }

    &.card-highlight {
        text-align: center;

        .highlight-title {
            text-align: left;
            line-height: 1em;
            font-weight: bold;
            color: #867A65;

            span {
                font-weight: normal;
                display: block;
            }
        }

        .highlight-number {
            font-size: 60px;
            font-weight: bold;
            color: #919191;
            margin: 1.25rem 0;
        }
    }

    .cm-icon {
        font-size: 70px;
        color: #C6C1BC;
        line-height: 1em;

        @include media-breakpoint-down(lg) {
            font-size: 50px;
            display: inline-block;
        }
    }

    .card-icon {
        background: #E4E4E4;
        border-radius: 100%;
        width: 70px;
        height: 70px;
        color: #FFFFFF;
        font-size: 45px;
        line-height: 70px;
        text-align: center;
    }

    .card-title {
        font-family: $font-primary;
        font-weight: bold;
        font-size: 18px;
        margin: 0;
    }

    .card-title-alt {
        color: $primary;
        text-align: left;
        font-weight: bold;
        font-size: 18px;

        i {
            display: inline;
        }
    }

    .card-bottom-text {
        line-height: 1rem;
        margin-bottom: 0;
    }

    .card-more {
        border-top: 1px solid $gray-light;
        text-decoration: none;
        display: block;
        color: $gray-dark;
        font-size: 14px;
        margin: 20px -10px -20px;
        padding: 10px;
    }

    .fixed-actions {
        position: absolute;
        text-align: center;
        line-height: 1rem;
        padding: 10px;
        right: 10px;
        top: 10px;

        &.active {
            .cm-floating {
                transform: scale(1);
            }
        }

        i.cm-icon {
            font-size: 1.2rem;
            display: block;
        }

        span.cm-text {
            white-space: nowrap;
            text-decoration: none;
            display: inline-block;
            font-size: 0.7rem;
            color: $gray;
        }
    }

    .cm-floating {
        @include transition;
        transform-origin: 0% 30px;
        position: absolute;
        transform: scale(0);
        background-color: $white-ice;
        border-radius: 3px;
        right: -250%;
        top: -50%;
        z-index: 1;
        filter: drop-shadow(0 0 3px rgba(0, 0, 0, 0.2));
        will-change: filter;
        white-space: normal !important;
        text-align: left;
        font-size: 14px;

        &:before {
            content: "";
            width: 30px;
            height: 30px;
            background-color: $white-ice;
            position: absolute;
            left: -3px;
            top: 15px;
            transform: rotate(45deg);
            z-index: -1;
        }

        .cm-title {
            font-size: 18px;
            color: $gray-dark;
            margin-bottom: 20px;

            i {
                margin-right: 10px;
                transform: translateY(1px);
                display: inline-block;
            }
        }

        .accordion-title {
            display: block;
            color: $gray-dark;
            text-decoration: none;
            padding: 10px 0;
            font-size: 14px;

            &:hover {
                color: $primary;
            }

            i {
                display: block;
                font-size: 20px;
                float: right;
                color: #E4E4E4;
            }
        }
    }

    .cm-floating-last {
        transform-origin: 50% 30px !important;
        right: 80% !important;

        &:before {
            left: unset !important;
            right: -3px;
        }
    }

    a.icon-link {
        display: block;
        font-size: 10px;
        color: $gray-medium;
        text-decoration: none;
        padding: 6px 8px;
        text-align: center;

        &:hover {
            color: $primary;
        }

        i {
            display: block;
            font-size: 20px;
        }
    }
}

// card school
.card-school {
    text-align: center;

    .card-icon {
        margin: 10px 0 20px;
    }

    .card-description {
        margin-bottom: 20px;
    }

    .cod {
        margin-bottom: 5px;
    }

    .btn.btn-sm {
        font-size: 13px;
        padding: 1px 10px;
    }

    .card-bottom-text {
        line-height: 1.25em;
        margin-bottom: 20px;
        margin-top: 20px;
        font-size: 13px;
    }
}

.card-professors {
    margin-bottom: 20px;

    .card-body {
        padding: 0.75rem 1.25rem;
    }

    .card-icon {
        width: 50px;
        height: 50px;
        font-size: 40px;
        line-height: 50px;
    }

    .card-description {
        margin-bottom: 0;
    }
}

// Card Menu
.card-menu {
    text-align: center;
    position: absolute;
    color: $gray-light;
    font-size: 16px;
    right: 0px;
    top: 5px;
    width: 30px;
    height: 30px;
    padding: 4px;
    z-index: 10;
    cursor: pointer;

    &.active,
    &:hover {
        color: $primary;
    }
}

// Card Adicionar Avaliação
.card.add-new {
    background-color: $white-ice;
    text-align: center;
    box-shadow: none;
    border: 1px solid #E1E0E0;
    display: flex;
    flex-flow: row;
    align-items: center;
    text-decoration: none;

    span {
        width: 100%;
        font-size: 18px;
        line-height: 24px;
        padding: 22px 0;
        color: $gray-dark;
        text-decoration: none;

        @include media-breakpoint-down(lg) {
            color: $primary;
        }

        .add-icon {
            color: $white;
            background-color: $primary;
            width: 50px;
            height: 50px;
            margin: 0 auto 20px auto;
            padding: 13px 0;
            border-radius: 30px;
            font-size: 25px;

            @include media-breakpoint-down(lg) {
                background-color: transparent;
                border: 1px solid $primary;
                color: $primary;
            }
        }
    }

    &:hover {
        box-shadow: none;
        border: 1px solid $gray;
        background-color: lighten($white-ice, 1%);
    }
}

.card-list {
    a {
        padding: 45px 20px;

        .add-icon {
            position: absolute;
            margin: 0;
            top: 50%;
            transform: translateY(-50%);
            left: 25px;
        }
    }
}

// Card Agendamento
.card-materia {
    .card-body {
        @include media-breakpoint-down(lg) {
            padding: 1rem;
        }

        .btn-outline-warning {
            margin-top: 30px;
            font-size: 13px;
            padding: 2px 20px;
        }
    }
}

// Card Avaliação
.card-question {
    padding: 25px;

    .badge {
        width: 50px;
        padding: 13px;
        font-size: 13px;
    }

    .subtitle-question {
        font-weight: bold;
        font-size: 20px;
        font-family: $font-primary;
        position: absolute;
        margin: -33px 65px;
    }

    .question {
        margin-top: 30px;

        p {
            font-size: 17px;
            color: #9B9B9B;
            max-width: 80%;
        }
    }

    .custom-control-input:checked+label {
        color: $primary-light;
    }

    .custom-control-input+label {
        span {
            margin-right: 10px;
        }
    }
}

// Card Time
.card-time {
    padding: 20px;

    .info-time {
        text-align: center;
        color: $primary;
        font-size: 15px;
        font-weight: bold;

        i {
            margin-right: 10px;
        }
    }

    .remaining {
        margin-top: 20px;
        background-color: $white-ice;
        text-align: center;
        border-radius: 5px;

        p {
            color: $gray-dark;
            font-size: 13px;
            margin-bottom: -10px;
            padding-top: 5px;
        }

        span {
            font-size: 30px;
            color: $primary;
            font-weight: bold;
        }
    }

    .btn {
        margin-top: 50px;
        padding: 10px;
    }
}

// Card Perguntas adicionadas
.card-adicionadas {
    padding: 15px;

    @include media-breakpoint-down(md) {
        display: none;
    }

    .card-title {
        font-size: 15px;
        color: $primary;

        i {
            margin-right: 10px;
        }

        .card-open {
            font-weight: bold;
            padding-top: 4px;
            float: right;
        }
    }

    .card-info {
        text-align: right;
        color: darkgray;

        p {
            margin: 0;
        }

        .card-info-number {
            color: $primary-dark;
            font-weight: bold;
            margin-top: -10px;
            font-size: 28px;
        }
    }

    .card-nav {
        margin: 15px 0;
    }

    .card-questions {
        display: inline-block;

        .question-badge {
            background-color: #f2f2f2;
            text-transform: uppercase;
            display: inline-block;
            margin: 0 3px 7px;
            position: relative;
            text-align: center;
            border-radius: 5px;
            font-weight: bold;
            line-height: 1rem;
            padding-top: 11px;
            cursor: pointer;
            font-size: 13px;
            height: 40px;
            width: 40px;


            a {
                transition: transform 100ms ease;
                background-color: $primary;
                border-radius: 10px;
                transform: scale(0);
                position: absolute;
                color: #FFFFFF;
                font-size: 18px;
                right: -6px;
                z-index: 1;
                top: -8px;
            }

            &:hover {
                a {
                    transform: scale(1);
                }
            }
        }
    }

    .btn {
        padding: 5px;
        min-width: auto;
    }

    .col-sm-6:first-child {
        padding-left: 15px;
        padding-right: 5px;
    }

    .col-sm-6:last-child {
        padding-right: 15px;
        padding-left: 5px;
    }
}

.card-adicionadas-mob {
    display: none;

    @include media-breakpoint-down(md) {
        margin-top: 80px;
        padding: 5px 15px;
        background-color: $primary-dark;
        display: block;
        border-radius: 0;

        .card-title {
            font-size: 16px;
            color: $white;
            font-weight: normal;
            display: inline-block;

            i {
                margin-right: 10px;
            }
        }
    }


    @include media-breakpoint-down(sm) {
        .card-title {
            font-size: 14px;
        }
    }
}

.adicionadas-content-mob {
    position: absolute;
    top: 110px;
    left: 0;
    right: 0;
    z-index: 2;
    margin: 0 15px;
    padding: 30px;
    text-align: center;

    .btn {
        @include media-breakpoint-down(xs) {
            font-size: 13px;
        }
    }
}

// Prévia
.card-previa {
    padding: 20px;
}

.card-relatorio-aluno {
    padding-right: 30px;

    .card-body {
        padding-bottom: 10px;
        padding-top: 10px;
    }

    .bar-chart {
        margin-top: 35px;
    }
}