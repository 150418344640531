@import '../scss/main.scss';

.navbar-toogle-menu{
    position: absolute;
    top: 10px;
    right: -20px;
}
@include media-breakpoint-down(sm) {
    .navbar-toogle-menu{
        display: none;
    }
}

.navbar .user-dropdown .ud-link {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.navbar {
    .hamburguer {
        color: white;
       
    }
    .fa-bars {
        font-size: 20px;
    }
}