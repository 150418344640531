div.cookiesBanner {
  background: #bf0913;

  &.hide {
    display: none;
  }
  
  div.container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin: 0 auto;
    padding: 7px 0;
    width: 960px !important;

    @media (max-width: 991.98px) {
      width: auto !important;
    }

    div.text {
      display: block;
      line-height: 1.5;

      p {
        text-align: center;
        color: #ffffff;
        margin-top: 0;
        margin-bottom: 1rem;

        a {
          font-weight: 700;
          text-decoration: underline;
          color: #ffffff;
          margin-left: 5px;
        }

        a:hover {
          font-weight: 700;
          text-decoration: underline;
          color: #ffffff;
        }

      }
      
    }

    div.buttons {
      display: block;
      margin-bottom: 5px;
      text-align: center;
      line-height: 1.5;

      a {
        display: inline-block;
        background-color: #414141;
        color: #ffffff;
        border-radius: 4px;
        padding: 8px 16px;
        margin: 0 7px 7px 0;
        cursor: pointer;
        width: 190px;
        text-align: center;
      }

      a:hover {
        background-color: #313131;
      }

      a:last-child {
        margin-right: 0;
      }

    }

  }

}