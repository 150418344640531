.register-form {
    @media (max-width: 767.98px) {
        .label {
            color: white !important;
        }
        .form-check-label {
            color: white !important;
        }
    }
}
 
.form-check-inline .form-group {
    display: inline-flex;
    flex-direction: row-reverse;
    line-height: 14px;
    margin: 0px 0 0 0;

    label{
        margin-left: 6px;
    }
}

.form-control.is-invalid, .form-control.is-valid{
    background-position: 95% calc(.375em + 0.8rem) !important;
}
  


input.switch {
    display: none;
  
    + label {
      position: relative;
      padding-left: 50px;
      user-select: none;
      cursor: pointer;
  
      &:before {
        content: '';
        transition: background-color 200ms ease;
        background-color: #D3D3D3;
        border-radius: 10px;
        position: absolute;
        height: 20px;
        width: 45px;
        left: 0;
        top: 0;
      }
  
      &:after {
        content: '';
        transition: left 200ms ease;
        background-color: #fff;
        border-radius: 10px;
        position: absolute;
        height: 16px;
        width: 16px;
        left: 2px;
        top: 2px;
      }
    }
  
    &:checked + label {
      color: #2CDBA5;
  
      &:before {
        background-color: #2CDBA5;
      }
  
      &:after {
        left: 27px;
      }
    }
  }
  

