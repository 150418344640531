.calendar {
    display: grid;
    grid-gap: 1px;
    //display: flex;
    background-color: #E0E0E0;
    padding: 1px 0;
    margin: 0 -50px;
    @media (max-width: 640px) {
       margin: 0 0px;
    }

    > div {
        background-color: #fff;
        padding: 20px;
        display: flex;
        align-items: center;

        &.current {
            background-color: $gray-light;
        }

        &.featured {
            background-color: $gray-lighty !important;
        }

        .card {
            margin: 0;
            width: 100%;
            color: $primary-light;
            line-height: 1em;
            margin-bottom: 10px;

            small,
            .small {
                font-size: 70%;
            }

            .card-body {
                padding: 7px;

                p {
                    margin: 0;
                    line-height: 1em;
                }

                i.icon {
                    font-size: 24px;
                    line-height: 1.5em;
                }

                .card-title {
                    text-transform: uppercase;
                    font-size: 14px;
                }

                p {
                    font-size: 10px;
                }
            }

            .fixed-actions {
                top: 0;
                right: 0;
                padding: 4px 5px;
                color: #D1D1D1;

                i.cm-icon {
                    font-size: 14px;
                }

                .card-menu {
                    position: relative;
                    top: auto;
                    right: auto;
                    width: auto;
                    height: auto;
                }
            }
    
            &.card-super-aula {
                color: $primary !important;
            }
    
            &.card-prova {
                color: $orange !important;
            }
    
            &.card-evento {
                color: $purple !important;
            }

            &.card-dia {
                color: $gray-dark;
                
                .card-prova {
                    color: $orange;
                }
                
                .card-default {
                    color: $primary-light;
                }
                
                .card-evento {
                    color: $purple;
                }
                
                .card-super-aula {
                    color: $primary;
                }

                .card-body {
                    padding: 20px 10px;

                    .card-title {
                        font-size: 18px;
                        margin-bottom: 5px;
                    }

                    p {
                        font-size: 16px;
                    }
                }

                .card-footer {
                    background-color: transparent;
                    padding: 0 10px;
                }
            }
        }
    }

    .col-label {
        text-align: center;
        padding: 10px;
        flex-wrap: wrap;

        span {
            display: block;
            line-height: 1em;
            width: 100%;
        }

        .week {
            font-size: 12px;
            color: #CCCCCC;
        }

        .day {
            font-size: 40px;
            @media (max-width: 640px){
                font-size: 25px;
            }
            font-weight: bold;
            color: #8F8F8F;
        }

        &.current span {
            color: $primary !important;
        }

        &.weekend span {
            color: #D1D1D1 !important;
        }
    }

    .row-label {
        justify-content: space-around;
        grid-column-start: 1;
        font-weight: bold;
        color: #999999;
        padding: 0;
    }

    &.year-calendar-wrapper {
        // grid-template-columns: repeat(3, minmax(auto, 300px));
        justify-content: space-evenly;  
        background-color: #FDFDFD;

        .card-month {
            margin-bottom: 50px;
            height: 286px;

            .card-body {
                padding: 15px;
                width: 100%;
            }
        }
    }

    &.week-calendar-wrapper {
        grid-template-columns: auto repeat(7, 1fr);
        @media (max-width: 640px){
          grid-template-columns: auto repeat(6, 1fr);
        }
        grid-template-rows: auto repeat(5, 1fr);
    }

    &.day-calendar-wrapper {
        grid-template-columns: 1fr 4fr;

        .months-wrapper {
            min-width: 325px;
            display: block;

            .card-month {
                margin-bottom: 20px;
            }
        }

        .class-wrapper {
            background-color: #FDFDFD;
            display: block;
            padding: 0;

            .card {
                max-width: 275px;
                display: inline-block;
                margin-right: 20px;
            }

            .day-header {
                color: $primary;
                max-height: 90px;
                background-color: #F8F8F8;
                padding: 20px;

                .week-name {
                    font-size: 12px;
                }

                .week-day {
                    font-size: 40px;
                    font-weight: bold;
                    line-height: 1em;
                }
            }

            .item {
                min-height: 150px;
                padding: 20px;
                border-top: 1px solid $gray-lighty;
                align-items: center;
                padding-left: 80px;
                position: relative;

                .day-name {
                    font-weight: bold;
                    position: absolute;
                    left: 0;
                    top: 0;
                    bottom: 0;
                    width: 80px;
                    display: flex;
                    align-items: center;
                    justify-content: space-around;
                }
            }
        }
    }

    &.month-calendar-wrapper {
     //   grid-template-columns: 1fr 4fr;

        .months-wrapper {
            min-width: 325px;
            display: block;

            .card-month {
                margin-bottom: 20px;
            }
        }

        .days-wrapper {
            background-color: #E1E0E0;
            grid-template-columns: repeat(5, 1fr);
            grid-gap: 1px;
            display: grid;
            width: 100%;
            padding: 0;
            align-items: initial;

            > div {
                background-color: #fff;
                padding: 10px;

                &.current {
                    background-color: #F5F5F5;
                }

                .week-name {
                    color: #CBCBCB;
                    font-size: 14px;
                }

                .week-day {
                    color: #8E8E8E;
                    font-size: 20px;
                    font-weight: bold;
                    line-height: 1em;
                    margin-bottom: 10px;
                }

                i.icon {
                    font-size: 20px;

                    &[class*=" ti-"] {
                        font-size: 17px;
                    }
                }
            }
        }
    }
}

.periodo {
    font-weight: bold;
    margin-bottom: 20px;

    button {
        background-color: transparent;
        border: none;
        padding: 0 5px;
        color: #D1D1D1;
        font-size: 25px;
        transform: translateY(3px);

        &:hover {
            color: $primary;
        }
    }
}

.card-month {
    max-width: 400px;
    margin: 0 auto;
    padding: 0 !important;

    .month-name {
        color: $gray-dark;
        font-weight: bold;
        font-size: 18px;
    }

    .card-month--days {
        grid-template-columns: repeat(7, 1fr);
        grid-template-rows: repeat(6, 1fr);
        text-align: center;
        display: grid;
        color: #8E8E8E;
        grid-gap: 5px;

        > div {
            padding: 7px 0;
            line-height: 1em;

            &.week {
                color: #CBCBCB;
            }

            &.disabled {
                color: #E4E4E4;
            }

            &.muted,
            &.current,
            &.featured {
                border-radius: 60px;
                width: 30px;
                height: 30px;
                color: #fff;
            }

            &.muted {
                background-color: $gray;
            }

            &.featured {
                background-color: $primary-light;
            }

            &.current {
                background-color: $primary;
            }
        }
    }
}
