.month-calendar-wrapper {
    display: flex !important;
    @media print {
        margin: 15px;
        .days-wrapper{
            gap: 0px !important;
            grid-gap: 0px !important;
            &>div{
                border: 1px solid gainsboro;
            }
        }
    }
}

.month-calendar--days-wrapper{
    @media (max-width: 640px){
        overflow-x: scroll;
    }
}