.school-card-icon {
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 10px;
}

.school-card-menu {
    height: 30px;
    text-align: center;
    padding: 0 10px;
    background: #e1e0e0;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -35px;
    right: -70px;
    z-index: 100;

    .arrow {
        border-right: 15px solid transparent;
        border-top: 20px solid #e1e0e0;
        height: 0;
        right: 63px;
        margin-top: -25px;
        position: relative;
        top: 35px;
        width: 0;
    }
}