.modal {
    overflow: auto;
    &.fade {
        .modal-dialog {
            transform: scale(0.95);
        }
    }

    &.show {

        display: block;

        .modal-dialog {
            transform: scale(1);
        }
    }

    .modal-dialog {
        max-width: 600px;

        &.modal-sm {
            max-width: 350px;
        }

        &.modal-md {
            max-width: 750px;
        }

        &.modal-large{
            max-width: 1000px;
        }
    }

    .modal-content {
        box-shadow: $shadow-default;
        border: none;
    }

    .modal-header {
        border: none;
    }

    .modal-body {
        padding: 0 2rem 2rem;
        text-align: center;
    
        i.icon {
            font-size: 70px;
            color: $primary;
        }
    
        .modal-title {
            text-transform: uppercase;
            font-weight: bold;
            font-size: 25px;
            color: $primary;
        }

        .modal-subtitle {
            font-size: 18px;
            font-weight: bold;
            color: $primary-light;
            margin-top: 20px;
        }

        .label {
            font-size: 18px;
        }

        p {
            color: inherit;
            margin: 20px 0;
        }
    }
    
    .modal-footer {
        padding: 0;
    }

    a.modal-button {
        border-right: 1px solid #e9ecef;
        justify-content: center;
        flex-direction: column;
        text-decoration: none;
        text-align: center;
        line-height: 1.2em;
        padding: 0 25px;
        font-size: 18px;
        color: #999;
        z-index: 9999;
        display: flex;
        height: 80px;
        flex: 1;
        margin: 0;
    
        @include media-breakpoint-down(sm) {
            padding: 0 15px;
        }
        
        &.modal-button:hover {
            color: $primary;
        }
        
        &:last-child {
            border-right: none;
        }
    }
}

.modal-backdrop {
    background: #5E646B;

    &.fade{
        display: none;
    }

    &.show {
        display: block;
    }
}

.card-title-modal {
    color: #88b2d6;
    text-align: left;
    padding-left: 10px;
    display: flex;
    font-weight: bold;
    margin: 0 !important;
    padding: 0 0 0 10px;
}

.card-text-modal {
    display: flex;
    margin: 0!important;
    padding: 5px 0 0 10px;
    font-size: 12px;
}

.row-grid {
    margin-left: 0px;
    margin-right: 0px;
    min-height: 120px;
    display: flex;
    align-items: center;
    border: 1px solid #ddd;

    &:nth-child(even){
        background-color: #fff;
    }

    &:nth-child(odd){
        background-color: #F0F0F0;
    }
}

.row-grid-title {
    margin-left: 0px;
    margin-right: 0px;
}

.col-grid-title {
    background-color: #88B2D6;
    height:50px;
    align-items: center;
    display: flex;

    &:first-child {
        border-radius: 5px 0 0 0;
    }

    &:last-child {
        border-radius: 0 5px 0 0;
        border-left: 1px solid #fff;
    }

    h6 {
        color:#fff;
        margin-bottom: 0px;
    }
}
