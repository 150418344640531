.form-control {
    background-color: $white-ice;
    border: 1px solid #e1e0e0;
    padding: 1rem 0.75rem;
    color: $gray-dark;
    // width: 100% !important;
    height: auto;

    // @include media-breakpoint-down(sm) {
    //     padding: 0.5rem 0.75rem;
    // }

    &:focus {
        background-color: $white-ice;
        border-color: $primary;
        box-shadow: none;
    }
}

span.form-control {
    position: relative;

    i {
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translateY(-50%);
        color: $green-ocean;
    }
}

select.form-control {
    height: 53px;
}

.form-control-mob {
    @include media-breakpoint-down(lg) { 
        font-size: 0.8rem;
      }
}

.form-group {
    margin: 30px 0 0 0;
    
    @include media-breakpoint-down(sm) {
        display: block;
        max-width: 100%;
    }

    label {
        font-family: $font-primary;
        color: $gray-dark;
        margin-bottom: 0;
        font-size: 14px;
    }

    &.selected {
        color: $green-ocean;
    }

    .select2 {
        @include media-breakpoint-down(md) {
            width: 100% !important;
        }
    }
}
/*
.form-group-mob {
    @include media-breakpoint-down(sm) {
        label {
            color: $white;
        }

        .form-control {
            color: $white;
            border-right: none;
            border-left: none;
            border-top: none;
            border-bottom: 1px solid $white;
            background-color: transparent;
            padding-left: 0;
        }
    }
}
*/
.toggle-password-wrapper {
    position: relative;

    .toggle-password {
        background-color: inherit;
        position: absolute;
        color: $gray-dark;
        cursor: pointer;
        padding: 15px;
        border: none;
        bottom: 0;
        right: 0;
        top: 0;
    }

    .form-control {
        padding-right: 45px;
    }
}

.custom-radio,
.custom-checkbox {
    .custom-control-input {
        ~ label.custom-control-label {
            padding-left: 25px;
            
            &:before {
                background-color: $white-ice;
                border: 1px solid #E1E0E0;
                left: 0;
            }
        }

        &:checked {
            ~ label.custom-control-label {
                &:before {
                    background-color: $white-ice;
                    border-color: $primary;
                    box-shadow: none;
                }

                &:after {
                    background-color: $primary;
                    background-image: none;
                    border-radius: 50%;
                    height: 8px;
                    width: 8px;
                    left: 4px;
                    top: 8px;
                }
            }
        }
    }
}

.select2 {
    width: 100% !important;
}

.select2-container--default
.select2-selection--single {
    border: 1px solid #e1e0e0;
    background: #F8F8F8;
    border-radius: 3px;
    padding: 0.72rem;
    height: auto;
    width: 100% !important;
}

.select2-container--default
.select2-selection--single
.select2-selection__arrow {
    height: 100%;
}

// Adicionar Arquivo
a.upload-file,
button.upload-file {
    background-color: #f8f8f8;
    border: 1px solid #e1e0e0;
    border-radius: 5px;
    text-align: center;
    color: #f78e00;
    line-height: 1em;
    display: block;
    padding: 20px;
    width: 100%;
    
    i {
        transition: transform 150ms ease;
        margin-bottom: 3px;
        font-size: 40px;
        display: block;
    }
    
    span {
        font-size: 14px;
    }
    
    &:hover {
        border: 1px solid #bfbfbf;
        text-decoration: none;
        
        i {
            transform: scale(0.9);
        }
    }
}

.amount {
    display: inline-block;
    color: $gray-dark;

    @include media-breakpoint-down(sm) {
        float: right;
    }

    .select2-container--default
    .select2-selection--single {
        padding: 0.02rem;
        height: 30px;
        width: 70px;
    }

    .select2-container--default
    .select2-selection--single
    .select2-selection__arrow {
        right: -25px;
    }
}

// custom radios and checkboxes
input.custom[type='radio'],
input.custom[type='checkbox'] {
    display: none;

    + label,
    + .sub-items > label {
        transition: all 100ms ease;
        margin-top: 15px;
        text-align: left;
        display: inline-block;
        user-select: none;
        color: $gray-dark;
        line-height: 1em;

        &:before {
            content: '';
            transition: all 100ms ease;
            background-image: url('../images/check.svg');
            background-size: 0;
            background-repeat: no-repeat;
            background-position: center;
            display: inline-block;
            width: 25px;
            height: 25px;
            border-radius: 4px;
            background-color: $white-ice;
            border: 1px solid #E1E0E0;
            margin-right: 10px;
            vertical-align: middle;
            transform: translateY(-2px);
        }
    }

    &:checked + label,
    &:checked + .sub-items > label {
        color: $primary;

        &:before {
            border-color: $primary;
            background-size: 15px;
        }
    }

    + .sub-items:not(:last-child) {
        border-bottom: 1px solid rgba(#C1C1C1, 0.3);
    }
}

// custom radios and checkboxes
input.custom-text[type='radio'],
input.custom-text[type='checkbox'] {
    display: none;

    + label {
        transition: all 100ms ease;
        margin-top: 0;
        margin-bottom: 15px;
        text-align: left;
        display: inline-block;
        user-select: none;
        padding: 10px;
        border-radius: 4px;
        background-color: $white-ice;
        border: 1px solid #E1E0E0;
        line-height: 1em;
    }

    &:checked + label {
        background-color: $primary-lightest;
        border-color: $primary;
        color: $primary;
    }
}

.group-checkboxes {
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    max-height: 0;
    transition: max-height 300ms ease-in-out;
    padding-left: 35px;

    &.show {
        max-height: 60px;
    }
}

.input-group {
    .input-group-prepend {
        .input-group-text {
            background-color: $white-ice;
            padding-left: 20px;
            padding-right: 20px;
            color: $primary-dark;
            font-size: 20px;
        }
    }
}
