.tab{
    width:100%;
    padding:25px 0;
    color:#444;
  }
  
  ul.inline{
    list-style:none;
    padding: 0;
    margin-bottom:0;
    -webkit-margin-before: 0;
    -webkit-margin-after: 0;
    -webkit-margin-start: 0px;
    -webkit-margin-end: 0px;
    -webkit-padding-start: 0px;

    li{
      display:inline-block;
      margin-left:0;
      padding:10px 20px;
      border-bottom:1px solid #ddd;
      transition: all .5s;
      font-weight:300;
      cursor:pointer;
      color:#aaa;
      font-weight: bold;

      &.selected{
        border-bottom:2px solid #999;
        color:#999;

        &.active{
          border-bottom:2px solid #0062cc;
        }
      }

      &.active{
        color:#0062cc;
      }
    }
  }

  .buttonHour{
    background-color: #fff;
    border: 1px solid #999;
    color: #999;
    border-radius: 3px;
    width: 40px;
    height: 40px;
    margin: 0 4px;
    font-size: 14px;
    font-weight: 700;
    cursor: pointer;

    &:first-child{
      margin: 0 4px 0 0;
    }

    &:last-child{
      margin: 0 0 0 4px;
    }

    &:hover{
      border: 1px solid #444;
      color: #444;
    }

    &.active{
      border: 1px solid #0062cc;
      color:#0062cc;
    }
  }

  .buttonShowMore{
    background-color: #fff;
    border: 1px solid #0062cc;
    color: #0062cc;
    border-radius: 3px;
    width: 40px;
    height: 40px;
    margin: 0 4px;
    font-size: 14px;
    font-weight: 700;
    cursor: pointer;
    display: inline-block;

    &.none{
      display:none;
    }
  }

  .removable {
    color: #CE1414;
    border: none;
    background: none;
    margin: auto;
    font-size: 16px;
    cursor: pointer;
  }
  
  .button-remove{
    display: flex;
    align-items: center;
    border-left: 1px solid #ccc;
  }

  .labelSelect {
    background-color: transparent;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid #ccc;

    &:disabled {
      border: none;
    }

    &.has-error {
      border-bottom: 1px solid #721c24;
      background-color: #f8d7da;
    }
  }