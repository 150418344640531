@import '../config/variables';

.icon-button-container {
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 70px;

    span {
        font-size: 15px;
        margin-top: -5px;
    }
}

.icon-button {
    width: 70px;
    height: 70px;
    background-color: transparent;
    //color: gainsboro;
    font-size: 45px;
    line-height: 70px;
    text-align: center;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;

    i {
        color: rgb(141, 141, 141);
    }
}