.notification {
    padding: 40px 0;
    border-bottom: 1px solid #E1E0E0;

    .title {
        color: $primary-light;
        font-size: 18px;
        font-weight: bold;
    }

    small {
        color: $gray-medium;
    }
}
