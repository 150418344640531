@import '../../globals';

.metadataRow {
  box-sizing: content-box;
  display: flex;
  margin-bottom: 10px;
}

.metadataLabel {
  border-right: 1px solid;
  font-size: 20px;
  padding: 10px;
  min-width: 40%;
  width: 42%;
}

.metadataContent {
  padding: 10px;
  min-width: 60%;
  width: 58%;
}

.class-details-file {
  color: $primary;
  cursor: pointer;
  background: transparent;
  border: none;
  padding: 0;
}

.superclassLabel {
  text-align: center;
  font-weight: 600;
  font-size: 0.8em;
  padding: 5px 0 0 0;
}

.superclassContent {
  text-align: center;
  padding: 0px 0 5px 0;
  border-bottom: 1px solid #8e8e8e;
  font-size: 0.9em;
}

@media screen and (max-width: 480px) {
  .modal-title {
    font-size: 21px !important;
  }

  .modal-subtitle {
    font-size: 16px !important;
  }

  .modal-button {
    text-transform: uppercase;
    font-size: 13px !important;
  }

  .metadataRow {
    display: -webkit-box;
  }

  .metadataLabel,
  .metadataContent {
    font-size: 14px;
  }
}
