/*.plansScheduled__list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .card {
    margin: 10px;
    width: 47%;
  }

  .card-list {
    a {
      padding: 0;
    }
    .fixed-actions {
      top: 5px !important;
      right: 5px !important;
    }
  }

  @media screen and (max-width: 1089px) {
    .card {
      width: 100%;
    }
  }
}*/

.modal-body.fullscreen {
  height: 80vh;
}

.modal.fullscreen .modal-dialog {
  max-width: 90% !important;
}

.scheduled-items-card {
  cursor: pointer;
  .scheduled-items-icon {
    width: 100%;

    .pe-7s-plus {
      font-size: 40px
    }
  }
}
