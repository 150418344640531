* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    @include media-breakpoint-down(sm) {
        user-select: none;
    }
}

.force-selection {
    user-select: all !important;
}

body {
    font-family: $font-primary;
    overflow-x: hidden;
    color: #8D8D8D;
    font-size: 16px;
    height: 100vh;

    // rules
    &:before,
    &:after {
        pointer-events: none;
        background: blue;
        position: fixed;
        z-index: 999999;
        // content: '';
    }

    // vertical rule
    &:before {
        height: 100%;
        left: 60px;
        width: 1px;
    }

    // horizontal rule
    &:after {
        width: 100%;
        height: 1px;
        top: 130px;
    }
}

.content-wrapper {
    background-color: #FDFDFD;
    padding-bottom: 100px;
    padding-left: 330px;
    padding-right: 50px;
    padding-top: 130px;
    position: relative;

    @include media-breakpoint-down(md) {
        padding: 100px 0 30px 0;
    }

    @include media-breakpoint-down(xs) {
        padding: 100px 0 100px 0;
    }

    &--expanded {
        padding-left: 150px;

        @include media-breakpoint-down(md) {
            padding-left: 0;
        }
    }

    .margin-bottom {
        margin-bottom: 50px;

        @include media-breakpoint-down(sm) {
            margin-bottom: 10px;
        }
    }
}

.loadmore,
.load-more {
    text-align: center;
    color: $gray-dark;
    font-size: 15px;
    display: block;
    padding: 5px 0;
    
    &:hover {
        text-decoration: none;
        color: $orange;
    }
}

.link-info {
    cursor: pointer;
    font-size: 14px;

    i {
        transform: translateY(3px);
        font-size: 1.5em;
    }
}

.tooltip {
    filter: drop-shadow(0 0 3px rgba(0, 0, 0, 0.2));
    opacity: 1 !important;
    width: 250px;

    .tooltip-inner {
        background-color: #F8F8F8;
        transform: translateY(-25%);
        padding: 15px 20px;
        text-align: left;
        color: #9A9A9A;
        max-width: 240px;
        opacity: 1;

        .tooltip-heading {
            margin-bottom: 10px;
            font-weight: bold;
            font-size: 16px;
            color: $orange;
        }

        .tooltip-item {
            margin-bottom: 10px;
            font-size: 12px;
        }
    }

    .arrow {
        &:before {
            border-left-color: #F8F8F8;
        }
    }
}

.align-center {
    display: inline-block;

    @include media-breakpoint-down(sm) {
        display: block;
        text-align: center;
        color: $gray-dark;
        font-weight: bold;
        margin-bottom: 10px;
    }
}

.display-none {
    @include media-breakpoint-down(sm) {
        display: none;
    }
}

.display-block {
    @include media-breakpoint-down(sm) {
        display: block;
    }
}

.margin-mob {
    @include media-breakpoint-down(md) {
        margin: 0 !important;
    }
}

.margin-top {
    @include media-breakpoint-down(lg) {
        margin-top: 30px !important;
    }
}

.mr-mob {
    @include media-breakpoint-down(sm) {
        margin-right: 15px !important;
    }
}

.border-none {
    @include media-breakpoint-down(sm) {
        border: none !important;
    }
}

.pr-mob {
    @include media-breakpoint-down(md) {
        padding-right: 0;
    }
}

.p-mob {
    @include media-breakpoint-down(xs) {
        padding: 0;
    }
}

.big {
    font-size: 18px;
}

.cod {
    color: $green-ocean;
    text-transform: uppercase;
    font-size: 17px;
    font-weight: bold;

    i {
        margin-left: 5px;
    }
}

.text-success {
    color: $green-ocean !important;
}

a.text-success:focus,
a.text-success:active,
a.text-success:hover {
    color: $green-ocean !important;
}

.dias-letivos {
    text-align: center;
    color: $primary-dark;
    margin-top: 20px;

    .number {
        font-size: 35px;
        font-weight: bold;
        line-height: 1em;
    }
}

.action-bar {
    align-items: flex-start;
    flex-direction: row;
    text-align: right;
    display: flex;

    @include media-breakpoint-down(sm) {
        background-color: $white-ice;
        margin-top: 20px;
    }

    .action-bar-item {
        display: inline-block;
        text-decoration: none;
        vertical-align: top;
        text-align: center;
        margin-left: 20px;
        color: $gray-dark;
        font-size: 13px;

        @include media-breakpoint-down(lg) {
            margin: auto;
            padding: 10px;
            font-size: 11px;
        }

        span {
            display: block;
        }

        i {
            font-size: 35px;
            display: block;
        }

        &:hover {
            color: $primary;
        }
    }
}

.modal .modal-body.modal-event-details {
    .modal-subtitle {
        color: $primary;
    }
    p {
        color: $gray-dark;
    }
}
