.plannerContainer___actionMenuWrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
}

.plannerContainer___helperFlexCenter {
  align-items: center;
  display: flex;
  flex-grow: 1;
  justify-content: flex-start;
  padding: 0 20px;
}

@media screen and (max-width: 949px) {
  .plannerContainer___actionMenuWrapper {
    flex-wrap: wrap;
  }
}

.planner-container-table {
  .ReactTable {
    height: 60vh;
  }
}

.plannerContainer___plannerName {
  padding: 0 20px;
  margin-bottom: 20px;
  font-weight: bold;
}

.plannerContainer___actionMenuWrapper2{
  font-size: 13px;
}