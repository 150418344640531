@media print {
    .card-appointment-day{
        display: none;
    }
}
.card-appointment-day-title{
    font-size: 11px !important;
    line-height: normal;
}
.card-appointment-day-discipline{
    font-size: 11px !important;
    line-height: normal;
}
.card-appointment-day-content{
    font-size: 11px !important;
    line-height: normal;
}