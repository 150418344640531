.CalendarStatusModal__title {
  font-weight: 600;
  font-size: 16px;
  padding: 10px 0 0 0;
}

.CalendarStatusModal__statusList {
  background: content-box;
  overflow: auto;
  max-height: 300px;
}

.CalendarStatusModal__statusWrapper {
  text-align: left;
  line-height: 1.7rem;
  margin: 15px 0;
}

.CalendarStatusModal__statusComment {
  font-style: italic;
}