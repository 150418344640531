.card-list {
  display: flex;

  .fixed-actions {
    right: -13px !important;
    top: -40px !important;
  }
}

.display-list {
  min-width: 80%;
  text-align: left;

  .action-list {
    position: absolute;
    right: 15%;
    top: 45%;
  }
}

.card-body {
  cursor: pointer;
}