.school-select__control{
    background-color: #F7F7F7 !important;
    border: 1px solid #e1e0e0 !important;
    color: #8D8D8D !important;
    height: auto;
}
.school-select__input{
    padding: 1rem 0.75rem;
}
.school-select__placeholder{
    margin-left: 15px !important;
}
.school-select__control--is-focused{
    background-color: #F7F7F7 !important;
    border: 1px solid #0D71C6 !important;
    box-shadow: none !important;
}
.school-select__value-container{
    padding: 0 !important;
    height: 58px;
}
.school-select__indicator-separator{
    background: none !important;
}
.school-select__menu{
    z-index: 99999 !important;
    background: white;
}

.css-1pcexqc-container.is-invalid .school-select__control{
    border-color: #dc3545!important;
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
    background-repeat: no-repeat;
    background-position: 97% calc(.375em + .8rem);
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.css-1pcexqc-container.is-valid .school-select__control{
    border-color: #28a745!important;
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: 97% calc(.375em + .8rem);
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.invalid-feedback {
    &.show {
        display: block!important;
    }
    &.hide {
        display: none!important;
    }
}
