.btn {
    font-family: $font-primary;
    padding: 1rem 0.75rem;
    min-width: 200px;
    font-size: 15px;

    @include media-breakpoint-down(sm) {
        padding: 0.5rem 0.75rem;
        min-width: auto;
    }
    
    &:not([class*="btn-outline-"]) {
        border: none;
    }

    &.btn-primary {
        background-color: $primary;
    }
    
    // default states
    &.btn-warning {
        background-color: $orange-light;
        color: #fff;
    }

    &.btn-light {
        background-color: #E1E0E0;
    }

    // hover and active states
    &:hover,
    &.active,
    &:active {
        &.btn-warning {
            background-color: $orange !important;
            color: #fff !important;
        }
    }

    // disabled
    &.disabled {
        //background-color: $gray-light;
    }
    
    &.btn-sm,
    &.btn-group-sm > .btn {
        border-radius: 7px;
        padding: 0 1.5rem 0.1rem;
        min-width: auto;
    }
    
    &.btn-outline-warning {
        border-color: $orange-light;
        color: $orange-light;
    
        &:hover,
        &:active {
            background-color: $orange !important;
            color: #fff !important;
            border-color: $orange;
        }
    }

    &.btn-outline-primary {
        border-color: $primary;
        color: $primary!important;
        background-color: transparent !important;
        font-weight: bold;
    
        &:hover,
        &:active {
            background-color: $primary !important;
            color: #fff !important;
            border-color: $primary;
        }
    }

    &.btn-outline-secondary {
        border-color: $secondary;
        color: $secondary!important;
        background-color: transparent !important;
        font-weight: bold;
    
        &:hover,
        &:active {
            background-color: $secondary!important;
            color: #fff !important;
            border-color: $secondary;
        }
    }

    &.btn-outline-success {
        border-color: $green-ocean;
        color: $green-ocean;
    
        &:hover,
        &:active {
            background-color: $green-ocean !important;
            color: #fff !important;
            border-color: $green-ocean;
        }
    }
}

// Adicionar Mais
a.add-more {
    text-decoration: none !important;
    color: $orange;
    margin: auto;

    @include media-breakpoint-down(sm) {
        background-color: $white-ice;
        text-align: center;
        box-shadow: none;
        border: 1px solid $gray-light;
        display: block;
        flex-flow: row;
        align-items: center;
        text-decoration: none;
        padding: 30px 60px;
    }
    
    &:hover,
    &:active {
        color: $orange;
    }
    
    i.ti-plus {
        background-color: $orange;
        border-radius: 30px;
        margin-right: 10px;
        color: #ffffff;
        padding: 10px;

        @include media-breakpoint-down(sm) {
            display: block;
            margin: auto;
            margin-bottom: 10px;
            max-width: 36px;
            background-color: transparent;
            border: 1px solid $orange;
            color: $orange;
            font-size: 25px;
            padding: 5px;
        }
    }
}

// Mobile
.btn-mob {
    @include media-breakpoint-down(sm) {
        margin: 20px 0 50px 0;

        a {
            width: 100%;
        }
    }
}

.btn-lg-mob {
    display: none;

    @include media-breakpoint-down(sm) {
        display: inline-block;
        margin-top: 20px;
    }
}

.btn-width-mob {
    @include media-breakpoint-down(sm) {
        width: 54%;
    }
    @include media-breakpoint-down(xs) {
        width: 40%;
    }
}

.btn-modal-mob {
    @include media-breakpoint-down(sm) {
        font-size: 15px;

        span {
            display: none;
        }
    }
}

.btn-sort {
    @include media-breakpoint-down(sm) {
        padding: 10px 20px !important;
    }
}

.btn-card-mob {
    @include media-breakpoint-down(lg) {
        padding: 0 0.9rem !important;
        max-width: 100%;
        font-size: 10px !important;
    }
}