.change-avatar {
    border-radius: 100%;
    position: relative;
    overflow: hidden;

    .ca-button {
        background-color: rgba(112, 112, 112, 0.5);
        font-family: $font-secondary;
        transform: translateZ(0);
        position: absolute;
        text-align: center;
        font-weight: bold;
        line-height: 0.6;
        font-size: 15px;
        display: block;
        color: white;
        bottom: 0;
        right: 0;
        top: 60%;
        left: 0;
        
        i.pe-7s-camera {
            @include transition;
            font-size: 45px;
            display: block;
        }
        
        &:hover {
            text-decoration: none;

            i.pe-7s-camera {
                transform: scale(0.9);
            }
        }
    }

    input[type=file] {
        display: none;
    }
}

.avatar-profile {
    background-color: $orange-dark;
    border-radius: 50px;
    text-align: center;
    position: relative;
    height: 50px;
    margin: auto;
    width: 50px;
    
    .avatar-image {
        object-fit: cover;
        height: 100%;
        width: 100%;
    }
    
    .avatar-country {
        text-transform: uppercase;
        background-color: $green;
        border-radius: 100px;
        position: absolute;
        font-weight: bold;
        color: #F5FF00;
        font-size: 14px;
        padding: 2px 0;
        height: 25px;
        width: 25px;
        left: -10px;
        top: 0;
    }
}

.avatar {
    background-color: $gray-light;
    display: inline-block;
    border-radius: 50px;
    text-align: center;
    position: relative;
    color: #ffffff;
    font-size: 35px;
    height: 70px;
    width: 70px;

    &:before {
        transform: translateX(-50%) translateY(-50%);
        position: absolute;
        left: 50%;
        top: 50%;
    }

    &.avatar-sm {
        height: 60px;
        width: 60px;
    }
}
