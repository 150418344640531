@import "../../globals.scss";

.action-modal {
  background: $gray;
  color: $white;
  font-size: 12px;
  padding: 10px;
  border-radius: 5px;
  z-index: 1000;
  position: absolute;
  right: 0;

  .action-modal-btn {
    cursor: pointer;
    margin: 2px;
  }
}