.week-calendar-row-mobile{
    display: flex;
    border-bottom: 1px solid gainsboro;
    min-height: 150px;
}
.week-calendar-row-mobile--hour{
    padding: 5px;
    width: 35px;
    display: flex;
    align-items: center;
    font-weight: bold;
    justify-content: center;
}
.week-calendar-row-mobile--cards{
    display: flex;
    justify-content: center;
    flex: 1;
    align-items: center;
}
