.card-icon-sm{
    background: #E4E4E4;
    border-radius: 100%;
    width: 70px;
    height: 70px;
    color: #FFFFFF;
    font-size: 45px;
    line-height: 70px;
    text-align: center;
    display: flex !important;
    align-items: center;
    justify-content: center;
}