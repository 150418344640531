.optin {
  .close {
    display: none;
  }
  .btn.disabled {
    background-color: #0D71C6!important;
  }
  .optin-form {
    margin: 50px 0 20px 0;
  }
  .invalid-feedback {
    display: block;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
  }

  .btn-secondary-optin {
    border: 1px solid #0D71C6;
    background: #fff;
    width: 100%;
    color: #0D71C6;
    padding: 14px;
    text-transform: uppercase;
    border-radius: 5px;
    cursor: pointer;
  }
  .btn-secondary-optin:hover {
    background: #0D71C6;
    color: #fff;
  }
  input.switch + label {
    padding-left: 55px;
  }

}

