.login-sign-tabs {
    @media (max-width: 767.98px) {
        .nav-link {
            color: white !important;
            &.active {
                border-bottom: 2px solid white !important;
            }
        }
        border-bottom-color: #a2bff570 !important; 
    }
}