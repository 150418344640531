.school-add-modal-form-container {
    .form-group {
        text-align: left !important;
    }
}

.school-add-modal-form-details {
    padding: 15px;
    border: 1px solid #e1e0e0;
    background: white;
    border-radius: 0.25rem;
    margin: 30px 0 0 0;
}


.school-add-modal-form-icon {
    cursor: pointer;
    margin: 10px
}
