.form-login{
    min-height: calc(100vh - 80px);
    @media (max-width: 768px) {
        .form-control-label{
            color: white;
            font-weight: bold;
        }
        .form-control{
            border-width: 2px;
        }
        .form-control-label.is-valid{
           color: white;
        }
        .form-control.is-valid{
        }
        .form-control-label.is-invalid{
            color: white;
        }
        .form-control.is-invalid{
            border-color: white;
        }
        .invalid-feedback{
            color: white;
        }
    }
}
.forgotPassword{
    margin-top: 20px;
    font-size: 12px;
    text-align: center;
}
@media (max-width: 767.98px) {
    .forgotPassword{
        color:#fff;
        font-size: 16px;
    }
}