h1, h2, h3, h4, h5, h6 {
  font-family: $font-secondary;
}

.page-title {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 20px;
  color: $primary;
  margin: 0;
  
  @include media-breakpoint-down(sm) {
    font-size: 18px;
  }
}

.section-title {
  font-family: $font-primary;
  margin-bottom: 20px;
  font-weight: bold;
  font-size: 20px;
  color: $primary;
  
  i {
    margin-right: 10px;
  }
}
