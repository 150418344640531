@import '../../globals.scss';

.planner-table-link {
  cursor: pointer;
  color: $primary;
  background: none;
  padding: 0;
  border: none;
}

.ReactTable .rt-table {
  overflow: visible;
  overflow-x: scroll;
}

.ReactTable {
  height: 100%;
  position: absolute;
  width: 100%;
}

// .ReactTable .rt-thead {
//   position: fixed;
//   background-color: #fff;
//   z-index: 1001;
// }

.ReactTable .rt-tbody {
  // margin-top: 10px;
  overflow: initial;
}

.table-page {
  .table-with-actions {
    @media (min-width: 992px) {
      width: 105%;
      left: 0px;
    }

    @media (min-width: 1400px) {
      width: 100%;
    }

    @media (min-width: 2000px) {
      width: 100%;
    }
  }
}

.table-with-actions {
  width: 100vw;
  height: 40px;
  position: absolute;

  @media (min-width: 992px) {
    width: 115%;
    position: relative;
    margin: 0 auto;
  }

  .table-header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    height: 100%;
    padding: 2px 20px;
    background: $gray;

    .search {
      border: none;
      border-radius: 3px;
      font-size: 12px;
      width: 170px;
      height: 30px;
      padding: 10px;
    }

    .actions-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .actions-btn {
      background: none;
      border: none;
      border-right: 1px solid $white;
      cursor: pointer;
      min-width: 30px;
      color: $gray-dark;
    }

    .icon-btn {
      font-size: 12px;
      margin-left: 3px;
    }
  }

  .element-wrapper {
    display: flex;
    position: relative;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    white-space: normal;
    height: 100%;
  }

  .element-modal {
    background: $gray;
    color: $white;
    font-size: 12px;
    padding: 10px;
    border-radius: 5px;
    z-index: 1000;
    position: absolute;
    align-self: flex-end;

    .element-modal-btn {
      cursor: pointer;
      margin: 2px;
    }
  }

  .table-row {
    text-align: center;
    height: 100%;
  }
}

@media print {
  * {
    background: transparent;
    color: #000;
    text-shadow: none;
  }

  #table {
    display: inline-block;
    zoom: 0.7;
    height: auto;
    // IE 5+
    // filter: progid:DXImageTransform.Microsoft.BasicImage(Rotation=3);

    #table-wrapper,
    #inner-table {
      display: inline-block;
    }

    .rt-table {
      overflow: hidden;
    }

    .rt-thead,
    .rt-tbody {
      height: auto;
      overflow: hidden;
    }

    .rt-tr {
      width: 100%;
    }

    .rt-td,
    .rt-th {
      border: 1px solid #000;

      &:last-child {
        display: none;
      }
    }
  }

  .pagination-bottom {
    display: none;
  }
}

.modal-body.fullscreen {
  height: 80vh;
}

.icon-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ReactTable .rt-thead.-header {
  top: 0;
  position: sticky;
  background-color: #fff;
  z-index: 1;
}
