@import '../scss/main';

.modal-button {
  cursor: pointer;
  &.primary{
      font-weight: bold;
      color: $primary !important;
  }                                                                                                                                                                                                                           
  &.disabled{
    opacity: .5;
  }
  margin: 0;
}