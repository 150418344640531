@import '../../globals.scss';

.element-modal {
  background: $gray;
  color: $white;
  font-size: 12px;
  padding: 5px;
  border-radius: 5px;
  z-index: 1000;
  position: absolute;
  top: 0;
  right: 0;

  .element-modal-btn {
    cursor: pointer;
    margin: 2px;
  }
}
