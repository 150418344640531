.report-bar {
    .bar-container {
        border-left: 1px solid gainsboro;
        padding-bottom: 5px;
        padding-top: 5px;
        margin-top: 10px;
        font-size: 11px;
        display: flex;
        color: rgb(13, 113, 198);;
        .bar {
            background-color: rgb(13, 113, 198);;
            height: 15px;
            display: inline-flex;
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
            margin-right: 5px;
        }
    }
    .icon-button-container {
        font-size: 10px;
    }
   
    .header {
        display: flex;
        justify-content: space-between;
        .student {
            display: flex;
            align-items: 'center';
            .initials {
                border-radius: 50%;
                color: white;
                font-weight: bold;
                font-size: 16px;
                width: 32px;
                height: 32px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            .student-info {
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin-left: 6px;
                .student-name {
                    font-weight: bold;
                    font-size: 15px;
                }
                .student-details {
                    font-size: 14px;
                }
            }
        }
    }
    .zero {
        font-size: 11px;
        width: 100%;
        text-align: left;
    }
}