.change-avatar{
    width: 220px;
    height: 220px;
    background-size: cover !important;
    .ca-button{
        color: white !important;
    }
}

.avatar-input-container {
    display: flex;
    justify-content: center;
}