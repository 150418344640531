@import '../../globals';

.calendar-wrapper{
  background: #E0E0E0;
}

.card-calendar{
  max-width: 200px;
  .icon {
    font-size: 21px !important;
    line-height: 1.2em !important;
  }
}

.card-calendar-not-applied {
  .card-appointment-title {
    color: #FF0000 !important;
    font-weight: bold;
  }
  .month-appointment-title {
    color: #FF0000 !important;
    font-weight: bold;
  }
  .card-appointment-day-title {
    color: #FF0000 !important;
    font-weight: bold;
  }
  .icon {
    color: #FF0000 !important;
    font-weight: bold;
  }
}
